import React, { useState } from "react";
import "../index.css";
import Counter from "./Counter";
import {
  EColorStatus,
  EPaymentStatus,
  EShippingType,
  EStatusValue,
  IHistory,
  IProduct,
  type Orders,
} from "../../../common/interfaces";
import { KitchenService } from "../../../services/KitchenService";
import moment from "moment";

import { OverlayTrigger, Tooltip, Popover } from "react-bootstrap";


const Card: React.FC<
  Orders & { className?: string; updateCallback?: () => void }
> = (props) => {
  const { id, className, updateCallback, status } = props;
  const [disable, setDisable] = useState(false);
  const startOrder = (orderId: string) => {
    setDisable(true);
    KitchenService.trackOrderStatus(orderId, {
      status: "Cooking",
    }).then((res) => {
      setDisable(false);
      updateCallback && updateCallback();
    });
  };
  const setDoneOrder = (orderId: string) => {
    setDisable(true);
    KitchenService.trackOrderStatus(orderId, {
      status: "Done",
    }).then(() => {
      setDisable(false);
      updateCallback && updateCallback();
    });
  };
  const setShipedOrder = (orderId: string, saleType?: string) => {
    setDisable(true);
    KitchenService.trackOrderStatus(orderId, {
      status: saleType === "shipped" ? "InTransit" : "Shipped",
    }).then(() => {
      setDisable(false);
      updateCallback && updateCallback();
    });
  };

  return (
    <>
      <div className={className}>
        <div
          className={`card ${
            EColorStatus[status as keyof typeof EColorStatus]
          }`}
          // style={{ height: "550px", overflow: "auto" }}
        >
          <div className="card-body" style={{ height: "500px", overflow: "auto" }}>
            <div className="row">
              <div className="col">
                {props.schedule !== "" && props.schedule !== null && (
                    <div style={{ fontSize: "14px", fontWeight: "bold", textAlign: "center" }}>
                      AGENDADO:{" "}
                      <span style={{ fontWeight: "normal", }}>
                        {/* {moment(props.schedule).format("DD/MM/Y HH:MM A")} */}
                        {moment(props.schedule).format("hh:mm A")}
                      </span>
                    </div>
                  )}
              </div>
            </div>
            <div className="row">
              <div className="col-6">
                
                    <b>Estatus:{" "}</b>
                    <span style={{ fontWeight: "normal" }}>
                      {EStatusValue[props.status as keyof typeof EStatusValue]}
                    </span>
                
              </div>

              <div className="col-6 text-end">
                
                  <b>VIA:</b>
                  {props?.origin_by == 'WEB-POS' ? (
                    <span style={{fontWeight: 'normal'}}> WEB-{props?.alias_id}</span>
                  ) : (
                    <span style={{fontWeight: 'normal'}}> {props?.origin_by}</span>
                  )}
                
              </div>
            </div>
            <div className="row">
              {/* <div className="col-6" style={{paddingTop: "5px", "fontSize": "18px"}}> */}
              <div className="col-6" style={{"fontSize": "18px", fontWeight: "bold"}}>
                #{id}
              </div>
              <div className="col-6">
                <Counter
                  dateToFormat={props.timeFromCooking}
                  status={props.status}
                  info={props.history}
                />
              </div>
            </div>
            <div className="card-order-detail row">
              <div className="order-detail-one-section col-12 row">
                <div className="card-section-one col-12" style={{ fontSize: "14px" }}>
                  
                    {props?.cliente == null ? (
                      <span style={{fontWeight: 'normal'}}> Público en General</span>
                    ) : (
                      <span style={{fontWeight: 'normal'}}> {props?.cliente?.nombre}</span>
                    )}
                  
                </div>
                
                {/* <div className="card-section-two col-12"></div> */}
                <div className="row">
                  <div className="col-8"><span style={{ fontWeight: "normal" }}>
                      {EShippingType[
                        props.shipped_type as keyof typeof EShippingType
                      ] ?? ""}
                    </span></div>
                  <div className="col-4 text-end">
                    <span style={{ fontWeight: "normal" }}>
                      {EPaymentStatus[
                        props.payment_status as keyof typeof EPaymentStatus
                      ] ?? ""}
                    </span>
                  </div>
                </div>
                
              </div>

              <div className="col-12" style={{marginTop: "10px"}}>

                <li
                  className="list-group"
                  style={{ height: "200px", overflow: "auto" }}
                >
                  {(() => {
                    // Filter items where producto.cocina === 1
                    const cocinaItems = props.detalles.filter(
                      (element) => element.producto.cocina === 1
                    );

                    // Generate the list
                    return props.detalles
                      .filter(
                        (element) =>
                          element.options !== "" || element.producto.cocina === 1
                      )
                      .map((detalle, i) => (

                        <OverlayTrigger
                          placement="auto"
                          overlay={
                            <Popover style={{background: "#ffff"}}>
                              <Popover.Header as="h2"><b>RECETA</b></Popover.Header>
                              <Popover.Body style={{color:"black"}}>
                                {detalle.producto.assembled_products ? detalle.producto.assembled_products.map((product: IProduct) => {
                                  // console.dir(product);
                                  // console.dir(product.pivot);

                                  return (<div key={product.id} className="text-start" style={{ fontSize: "16px", paddingBottom: "8px"}}> - <b>({product.pivot.quantity})</b> {product.name} <br /></div>)
                                }) : ''}
                              </Popover.Body>
                            </Popover>
                          }
                        >
                        <li
                          className="list-group-item d-flex justify-content-between align-items-start"
                          key={detalle.id}
                        >
                          <span className="me-2">
                            {i + 1}/{cocinaItems.length}
                          </span>
                          <div className="ms-2 me-auto" style={{ fontSize: "12px" }}>
                            <div className="fw-bold">{detalle?.producto.name}</div>
                            {detalle.options !== "" &&
                              detalle.options?.split(",").map((option, i) => (
                                <span key={i}>
                                  - {option}
                                  <br />
                                </span>
                              ))}
                          </div>
                          <span className="badge bg-primary rounded-pill">
                            {Number(detalle.cantidad).toFixed(2)}
                          </span>
                        </li>

                        </OverlayTrigger>
                      ));
                  })()}
                </li>
                
              </div>

            </div>
            
            <div className="row">
              <div className="col-12">
                <div style={{ marginTop: "5px" }}>
                  {props.notes_order && (
                    <>
                      <b>NOTAS:</b>{" "}
                      <span style={{overflow: "auto", fontSize: "14px"}}>
                        {props.notes_order}
                      </span>
                    </>
                  )}
                </div>
              </div>
            </div>
            
          </div>
          
          <div className="btn-group" role="group">
            {status === "Schedule" && (
              <>
                <button
                  disabled={disable}
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => {
                    startOrder(`${id}`);
                  }}
                >
                  Iniciar
                </button>
              </>
            )}
            {status === "Pending" && (
              <>
                <button
                  disabled={disable}
                  type="button"
                  className="btn btn-danger"
                  onClick={() => {
                    startOrder(`${id}`);
                  }}
                >
                  Iniciar
                </button>
              </>
            )}
            {status === "Cooking" && (
              <>
                <button
                  disabled={disable}
                  type="button"
                  className="btn btn-warning"
                  onClick={() => {
                    setDoneOrder(`${id}`);
                  }}
                >
                  Terminar
                </button>
              </>
            )}
            {status === "Done" && (
              <>
                <button
                  disabled={
                    disable ||
                    (props.payment_status === "pending" &&
                      props.shipped_type !== "shipped" &&
                      props.shipped_type !== "inRoom")
                  }
                  type="button"
                  className="btn btn-success"
                  onClick={() => {
                    setShipedOrder(`${id}`, props.shipped_type);
                  }}
                >
                  {props.shipped_type === "shipped"
                    ? "Entregar a repartidor"
                    : "Entregar"}
                </button>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Card;
