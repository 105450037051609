import React, { useState, useEffect } from "react";
import ScheduleIcon from "@mui/icons-material/Schedule";
import { IconButton } from "@mui/material";
import { HistoryStatus } from "./HistoryStatus";
import { IHistory } from "../../../common/interfaces";
interface CounterProps {
  status?: string;
  initTime?: {
    hours: number;
    minutes: number;
    seconds?: number;
  };
  dateToFormat?: string;
  info?: IHistory[];
}

const Counter: React.FC<CounterProps> = (props) => {
  const { status, initTime, dateToFormat, info } = props;
  const [hours, setHours] = useState(initTime?.hours ?? 0);
  const [minutes, setMinutes] = useState(initTime?.minutes ?? 0);
  const [seconds, setSeconds] = useState(initTime?.seconds ?? 0);
  const [isRunning, setIsRunning] = useState(false);
  const [display, setDisplay] = useState(false);
  // const [startCount, setStartCount] = useState(false)
  useEffect(() => {
    const [hour, min, sec] = dateToFormat?.split(":") ?? [0, 0, 0];
    setHours(Number(hour));
    setMinutes(Number(min));
    setSeconds(Number(sec));
    // console.log({ status });
    if (status === "Pending") {
      setIsRunning(true);
    }
    if (status === "Cooking") {
      setIsRunning(true);
    }
  }, []);
  useEffect(() => {
    if (status === "Pending") {
      setIsRunning(true);
    }
    if (status === "Cooking") {
      setIsRunning(true);
    }
    if (status === "Done") {
      setIsRunning(false);
    }
    if (status === "ToShip") {
      setIsRunning(false);
    }
    if (status === "Shipped") {
      setIsRunning(false);
    }
  }, [status]);
  useEffect(() => {
    let intervalId: NodeJS.Timeout | undefined;

    if (isRunning) {
      intervalId = setInterval(() => {
        setSeconds((prevSeconds) => (prevSeconds + 1) % 60);

        if (seconds === 59) {
          setMinutes((prevMinutes) => (prevMinutes + 1) % 60);

          if (minutes === 59) {
            setHours((prevHours) => prevHours + 1);
          }
        }
      }, 1000);
    } else {
      clearInterval(intervalId);
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [isRunning, seconds, minutes, hours]);

  function showHistory(): void {
    setDisplay(true);
  }

  return (
    <>
      <HistoryStatus display={display} setDisplay={setDisplay} info={info} />
      <div className="text-end" style={{fontSize: "15px", fontWeight: "bold"}}>
        {`${String(hours).padStart(2, "0")}:${String(minutes).padStart(
          2,
          "0"
        )}:${String(seconds).padStart(2, "0")}`}
        <IconButton onClick={() => showHistory()} color="primary" title="Historial de Estatus" style={{padding: "0px", marginLeft:"8px"}}>
          <ScheduleIcon />
        </IconButton>
      </div>
    </>
  );
};

export default Counter;
