export interface IResponse<T> {
  success: boolean;
  message: string;
  object?: T;
}

export type TPaymentMethod =
  | "debit_card"
  | "credit_card"
  | "cash"
  | "other"
  | "usd"
  | "gift_card"
  | "transfer"
  | "vales";

export interface ICashBox {
  id: number;
  open_amount: number;
  close_amount: number;
  usd_open_amount: number;
  usd_close_amount: number;
  user_id: number;
  warehouse_id: number;
  cash_number: number;
  status?: string;
  created_at?: string;
  updated_at?: string;
  close_cash?: string;
  close_card?: string;
  close_vales?: string;
  close_usd?: string;
}

export interface IAuth {
  token?: string;
  userId?: string;
  userName?: string;
  userRole?: string;
  userAvatar?: string;
}

export interface TAuthContext {
  auth: IAuth;
  saveAuth: (auth: IAuth) => void;
  loadAuth: () => IAuth;
}

export interface ILink {
  url?: string;
  label: string;
  active: boolean;
}

export interface IResultPagination<T> {
  current_page: number;
  data: T[];
  first_page_url: string;
  from: number;
  last_page: number;
  last_page_url: string;
  links: ILink[];
  next_page_url: string;
  path: string;
  per_page: string;
  prev_page_url: null;
  to: number;
  total: number;
}

export interface IWarehouse {
  id: number;
  name: string;
  address: string;
  phone: string;
  deleted_at?: any;
  created_at: string;
  updated_at: string;
}

export interface IInventory {
  id: number;
  warehouse_id: number;
  product_id: number;
  quantity: number;
  cost: string;
  created_by: string;
  created_at: Date;
  updated_at: Date;
}

export interface IProduct {
  id: number;
  name: string;
  description: string;
  image: null;
  type: string;
  min_stock: string;
  max_stock: string;
  product_loss_percentage: string;
  department_id: number;
  product_type_id: number;
  cost: string;
  price_type: number;
  price_1: number;
  price_2: number;
  price_3: number;
  price_4: number;
  price_5: number;
  default_price: null;
  book_id: null;
  iva_id: null;
  ieps_id: null;
  unit_id: null;
  supplier_id: number;
  barcode: string;
  alt_code: string;
  fiscal_unit_id: string;
  fiscal_prod_serv_id: string;
  hidden: string;
  is_active: null;
  is_bulk: string;
  created_by: string;
  edited_by: null;
  created_at: Date;
  updated_at: Date;
  deleted_at: null;
  inventory: IInventory[];
  iva?: IIva;
  book?: IBook;
  ieps?: IIeps;
  quantity?: number | string;
  discount?: number;
  options?: IProductOption[];
  selectedOptions?: string;
  assembled_products?: IProduct[];
  cocina?: number;

  pivot?: any;
}

export interface IIva {
  id: number;
  porcentaje: number;
}
export interface IIeps {
  id: number;
  porcentaje: number;
}

export interface IBook {
  id: number;
  name: string;
  type: number;
  margin_1: number;
  margin_2: number;
  margin_3: number;
  margin_4: number;
  margin_5: number;
  created_at: any;
  updated_at: string;
}

export interface ISale {
  id: number;
  cliente_id: number;
  usuario_id: number;
  fecha: string;
  descuento: string;
  total: string;
  subtotal: string;
  iva: string;
  ieps: string;
  metodoPago: string;
  formaPago: string;
  usoCFDI: string;
  notas: string;
  created_at: string;
  updated_at: string;
  deleted_at?: any;
  status: string;
  sale_type: string;
  origin_by: string;
  cliente: ICustomer;
  usuario: IUser;
  facturas: IInvoice[];
  payments?: IPayment[];
  detalles: ISaleDetail[];
}

export interface ISaleDetail {
  id: number;
  venta_id: number;
  producto_id: number;
  cantidad: string;
  precio: string;
  descuento: string;
  subtotal: string;
  iva: string;
  ieps: string;
  total: string;
  unidad_sat: string;
  clave_prod_serv: string;
  clave_unidad: string;
  created_at: string;
  updated_at: string;
  deleted_at?: any;
  producto: IProduct;
  options?: string;
}

export interface ICustomer {
  id: number;
  nombre: string;
  rfc: string;
  direccion: string;
  codigoPostal: string;
  regimenFiscal: string;
  usoCFDI: string;
  email: string;
  deleted_at?: any;
  created_at?: string;
  updated_at?: string;
  telefono?: string;
  direccion2?: string;
}

export interface IUser {
  id: number;
  nombre: string;
  usuario: string;
  password: string;
  perfil: string;
  foto: string;
  estado: string;
  ultimo_login: string;
  fecha: string;
}

export interface IInvoice {
  id: number;
  venta_id: number;
  folio: string;
  serie: string;
  fecha: string;
  forma_pago: string;
  exportacion: string;
  condiciones_pago: string;
  metodo_pago: string;
  moneda: string;
  tipo_cambio: string;
  subtotal: string;
  descuento: string;
  total: string;
  iva: string;
  ieps: string;
  lugar_expedicion: string;
  tipo_comprobante: string;
  emisor_nombre: string;
  emisor_rfc: string;
  emisor_regimen_fiscal: string;
  receptor_regimen_fiscal: string;
  receptor_nombre: string;
  receptor_rfc: string;
  receptor_domicilio_fiscal: string;
  receptor_uso_cfdi: string;
  status: string;
  uuid: string;
  fecha_timbrado: string;
  provedor_timbrado: string;
  xml: string;
  pdf: string;
  observaciones: string;
  created_at: string;
  updated_at: string;
  deleted_at?: any;
}

export interface ILinks {
  url?: any;
  label: string;
  active: boolean;
}

export interface IPayment {
  id?: number;
  venta_id?: number;
  amount: number;
  payment_method: TPaymentMethod;
  cash_received: number;
  exchange_rate?: number;
  usd?: number;
  auth_code?: string;
  created_at?: string;
  updated_at?: string;
  status?: string;
}

export type TRequestSale = Partial<ISale> & {
  orderProducts: IProduct[];
  sale_type: "Simple" | "Order";
  user_id?: number;
  warehouse_id?: number;
  cashBox: ICashBox;
  cash_received?: number;
  notes?: string;
};
export type TStatusSale =
  | "Shipped"
  | "Cancelled"
  | "Pending"
  | "Await"
  | "OnPrepare"
  | "Ready"
  | "OnShipping";

export enum EMapStatusSale {
  Shipped = "Entregado",
  Cancelled = "Cancelado",
  Pending = "Pendiente",
  Await = "Esperando",
  OnPrepare = "Preparando",
  Ready = "Listo para Enviar",
  OnShipping = "Enviando",
}

export type TSale = "Simple" | "Order";
export type TChashBoxDetails = ICashBoxDetail & {
  status: TStatusSale;
  payments?: string;
  sale_type: TSale;
  domicilio?: number;
  ss?: string;
};

export interface ICashBoxDetail {
  id: number;
  cash_box_id: number;
  operation: string;
  operation_id: number;
  amount: string;
  type: string;
  created_at: string;
  updated_at: string;
}

export interface ITablajeria {
  id: number;
  name: string;
  product_id: number;
  product_loss_percentage: string;
  status: string;
  created_by: string;
  deleted_at?: any;
  created_at: string;
  updated_at: string;
  inventory: IInventory;
  product: IProduct;
  details: Details[];
}

export interface Details {
  id: number;
  board_process_id: string;
  base_product_id: number;
  result_product_id: number;
  created_by: string;
  created_at: string;
  updated_at: string;
  result_product: IProduct;
}

export interface IGifCard {
  id: number;
  name: string;
  status: string;
  amount: string;
  created_by: string;
  created_at: string;
  updated_at: string;
}

export interface IProductOption {
  id: number;
  name: string;
  sub_options: string;
  created_at?: any;
  updated_at: string;
  pivot: IPivot;
}

export interface IPivot {
  product_id: number;
  option_id: number;
}

export interface User {
  id: number;
  warehouse_id?: any;
  nombre: string;
  usuario: string;
  perfil: string;
  foto: string;
  estado: string;
  ultimo_login: string;
  fecha: string;
  deleted_at?: any;
  created_at?: any;
  updated_at?: any;
}

export interface ISupplier {
  id: number;
  nombre: string;
  rfc: string;
  calle: string;
  nExt: string;
  colonia: string;
  localidad: string;
  municipio: string;
  estado: string;
  pais: string;
  cp: string;
  telefonos: string;
  dias: string;
  balance: string;
  otorgado: string;
  banco: string;
  nCuenta: string;
  t_pago: string;
  emails: string;
  diasVisita?: string;
  permitirPedidosPos: number;
  iva?: any;
  created_at: string;
  updated_at: string;
  is_billable: string;
}

export interface IPurchaseOrder {
  id: number;
  supplier_id: number;
  user_id: number;
  warehouse_id: number;
  date: string;
  status: string;
  note: string;
  total: string;
  tax: string;
  discount: string;
  grand_total: string;
  dias: string;
  is_credit: string;
  deleted_at?: any;
  created_at: string;
  updated_at: string;
  supplier: ISupplier;
  user: IUser;
  warehouse: IWarehouse;
  details: IDetails[];
}

export interface IDetails {
  id: number;
  purchase_orders_id: number;
  options: string;
  product_id: number;
  quantity: string;
  price: string;
  discount: string;
  tax: string;
  total: string;
  deleted_at?: any;
  created_at: string;
  updated_at: string;
  producto: IProduct;
}

export const mappingOperations: Record<string, any> = {
  venta: { type: "Venta", code: "S-" },
  purchase_orders_receipt: { type: "Recepción de OC", code: "RPO-" },
  board_process: { type: "Proceso Tablero", code: "T-" },
  board_process_executed: { type: "Proceso Tablero Ejecutado", code: "T-" },
  productions_executeds: { type: "Producciones Ejecutadas", code: "P-" },
  warehouse_transfer: { type: "Transferencia Almacen", code: "T-" },
  withdraw: { type: "Retiro de Efectivo", code: "WT-" },
  bill: { type: "Gasto", code: "G-" },
};

export interface IExpensesSupplier {
  id: number;
  name: string;
  rfc: string;
  address: string;
  phone: string;
  email: string;
  contact?: any;
  observations?: any;
  status: number;
  created_at: string;
  updated_at: string;
}

export interface IExpensesConcept {
  id: number;
  name: string;
  description: string;
  type?: any;
  status: string;
  bills_suppliers_id?: any;
  deleted_at?: any;
  created_at: string;
  updated_at: string;
}

export interface ProductPO {
  quantity: number;
  iva: number;
  iva_percent: number;
  subtotal: number;
  total: number;
  price: number;
  product: IProduct;
}

export interface ILogin {
  username: string;
  password: string;
}

export interface IKitchen {
  id: number;
  user_id: number;
  warehouse_id: number;
  status: string;
  created_at: string;
  updated_at: string;
  orders: Orders[];
}

export interface Orders {
  id: number;
  cliente_id: number;
  usuario_id: number;
  fecha: string;
  descuento: string;
  total: string;
  subtotal: string;
  iva: string;
  ieps: string;
  metodoPago: string;
  formaPago: string;
  usoCFDI: string;
  notas: string;
  created_at: string;
  updated_at: string;
  deleted_at?: any;
  status: string;
  sale_type: string;
  origin_by: string;
  warehouse_id: number;
  pivot: IPivot;
  cliente: ICustomer;
  detalles: ISaleDetail[];
  timeFromCooking?: string;
  shipped_type?: string;
  payment_status?: string;
  schedule?: string;
  history?: IHistory[];
  notes_order?: string;
  alias_id?: string;
}

export interface IHistory {
  id: number;
  order_id: number;
  status: string;
  previous_status_change: string;
  difference_time: string;
  created_at: string;
  updated_at: string;
}

export interface IEvent {
  success: boolean;
  message: string;
  object: IObject;
}

export interface IObject {
  warehouse_id: number;
  order_id: number;
  kitchen_id: number;
}

export enum EColorStatus {
  Pending = "border-danger",
  Cooking = "border-warning",
  Done = "border-success",
  InTransit = "border-info",
  Schedule = "border-secondary",
}

export enum EStatus {
  Pending = "danger",
  Cooking = "warning",
  Done = "success",
  InTransit = "info",
  Shipped = "primary",
  Schedule = "secondary",
}

export enum EStatusValue {
  Pending = "Pendiente",
  Cooking = "En Preparacion",
  Done = "Terminado",
  InTransit = "En Ruta",
  Shipped = "Entregado",
  Schedule = "Agendado",
}

export enum EPaymentStatus {
  pending = "Pendiente de Pago",
  paid = "Pagado",
}
export enum EShippingType {
  onSite = "Recoger en Tienda",
  inRoom = "Comedor",
  shipped = "Domicilio",
}
export interface IScheduleOrder {
  id: number,
  order_id: number,
  schedule_data: string,
  warehouses_id: number,
  created_at: string,
  updated_at: string,
  order: Orders
}